<template>
  <div class="purchase-list-footer">
    <Button variant="inverse" to="/offer/material-list">
      <template v-slot:icon-left>
        <ArrowLeftSVG class="icon button__icon-animate" />
      </template>
      {{ $t('OFFER.TO_MATERIAL') }}
    </Button>

    <Button
      variant="inverse"
      @click="delegateDownload"
      :loading="isDownloading"
    >
      <template v-slot:icon-left>
        <PdfIconSVG class="icon" />
      </template>
      {{ $t('OFFER.DOWNLOAD_PDF') }}
    </Button>

    <Button
      v-if="$i18n.locale === 'sv'"
      :href="redirectUrl"
      target="_blank"
      :disabled="isGetRedirectUrlLoading"
    >
      {{ $t('SHOP.GO_TO_SHOP') }}
      <template v-slot:icon-right>
        <ArrowRightSVG class="icon button__icon-animate" />
      </template>
    </Button>
  </div>
</template>

<script>
import Button from '@/components/Button';
import PdfIconSVG from '@/assets/images/offer/pdf-icon.svg?inline';
import ArrowLeftSVG from '@/assets/images/icon-long-arrow-alt-left-solid.svg?inline';
import ArrowRightSVG from '@/assets/images/icon-long-arrow-alt-right-solid.svg?inline';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'PurchaseListFooter',
  components: {
    Button,
    PdfIconSVG,
    ArrowLeftSVG,
    ArrowRightSVG,
  },
  methods: {
    ...mapActions(['getRedirectUrl']),
    delegateDownload() {
      this.$emit('downloadPdf');
    },
  },
  computed: {
    ...mapState(['isDownloading', 'isGetRedirectUrlLoading', 'redirectUrl']),
  },
  mounted() {
    if (this.$i18n.locale === 'sv') {
      this.getRedirectUrl();
    }
  },
};
</script>

<style lang="scss">
.purchase-list-footer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  justify-content: flex-end;

  @include min-width(md) {
    justify-content: flex-end;
    grid-template-columns: repeat(3, auto);
    transform: translateY(1.5rem);
  }

  @include min-width(lg) {
    transform: translateY(1.5rem);
    grid-template-columns: repeat(3, auto);
  }
}
</style>
