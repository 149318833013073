<template>
  <Card padding="none" shadow="medium">
    <div class="grid-table">
      <div class="grid-table__row grid-table__row--purchase-list">
        <div class="grid-table__column">
          <a
            :href="`${BASE_WEBSTORE_PRODUCT_URL}/${selectedProduct.artNo}`"
            target="_blank"
            rel="noopener noreferrer"
            class="text-strong"
          >
            {{ selectedProduct.artNo }}
          </a>
        </div>
        <div class="grid-table__column">
          <a
            :href="`${BASE_WEBSTORE_PRODUCT_URL}/${selectedProduct.artNo}`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <figure class="product-image">
              <img
                v-if="selectedProduct.imgUrl"
                :src="selectedProduct.imgUrl"
                @error="() => (selectedProduct.imgUrl = null)"
                :alt="selectedProduct.artNo"
                class="product-image__image"
              />
              <PhotoSVG v-else class="icon icon--large product-image__image" />
            </figure>
          </a>
        </div>
        <div class="grid-table__column">
          <div class="purchase-list-table-data__title">
            <a
              :href="`${BASE_WEBSTORE_PRODUCT_URL}/${selectedProduct.artNo}`"
              target="_blank"
              rel="noopener noreferrer"
              class="text-strong text-primary"
            >
              <h6 class="text-uppercase">
                {{ $mt(selectedProduct.name) }}
              </h6>
            </a>
            <StockLevelStatus
              v-if="$i18n.locale === 'sv'"
              :status="stockLevelStatus"
            ></StockLevelStatus>
          </div>
        </div>
        <div
          class="grid-table__column grid-table__column--has-mobile-header grid-table__column--border grid-table__column--center"
        >
          <div class="grid-table__mobile-header">
            {{ $t('OFFER.CHOSEN_QUANTITY') }}:
          </div>
          <div>{{ selectedProduct.chosenQuantity }}</div>
        </div>
        <div
          class="grid-table__column grid-table__column--has-mobile-header grid-table__column--border grid-table__column--center"
        >
          <div class="grid-table__mobile-header">{{ $t('OFFER.PU') }}:</div>
          <div>{{ selectedProduct.unitsInPack }}</div>
        </div>
        <div
          class="grid-table__column grid-table__column--has-mobile-header grid-table__column--border grid-table__column--center"
        >
          <div class="grid-table__mobile-header">{{ $t('OFFER.No_PU') }}:</div>
          <div>{{ selectedProduct.packsNeeded }}</div>
        </div>
        <div
          class="grid-table__column grid-table__column--has-mobile-header grid-table__column--border grid-table__column--right text-nowrap"
        >
          <div class="grid-table__mobile-header">
            {{ $t('OFFER.PACKAGE_PRICE') }}:
          </div>
          <ProductPrice :product="selectedProduct"></ProductPrice>
        </div>
        <div
          class="grid-table__column grid-table__column--has-mobile-header grid-table__column--border grid-table__column--right text-nowrap"
        >
          <div class="grid-table__mobile-header">
            {{ $t('OFFER.TOTAL_AMOUNT') }}:
          </div>
          <strong>
            {{ selectedProduct.price | formatPrice }}
          </strong>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/Card';
import { BASE_WEBSTORE_PRODUCT_URL } from '@/constants';
import PhotoSVG from '@/assets/images/offer/camera-solid.svg?inline';
import StockLevelStatus from '@/components/StockLevelStatus.vue';
import { isInStock } from '@/utils';
import ProductPrice from '@/components/offer/general/ProductPrice.vue';

export default {
  name: 'PurchaseListTableData',
  components: { ProductPrice, Card, PhotoSVG, StockLevelStatus },
  props: {
    selectedProduct: {
      type: Object,
      required: true,
    },
  },
  computed: {
    BASE_WEBSTORE_PRODUCT_URL() {
      return BASE_WEBSTORE_PRODUCT_URL;
    },
    packsNeeded() {
      return Math.ceil(
        this.selectedProduct.chosenQuantity / this.selectedProduct.unitsInPack,
      );
    },
    stockLevelStatus() {
      return isInStock(this.selectedProduct, this.packsNeeded)
        ? 'in-stock'
        : 'online';
    },
  },
};
</script>

<style lang="scss">
.purchase-list-table-data__title {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 100%;
}
</style>
