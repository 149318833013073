<template>
  <div class="purchase-list">
    <Hero>
      <h2>{{ $t('PAGE_TITLE') }} / {{ $t('OFFER.PURCHASE_LIST') }}</h2>
    </Hero>

    <ListInstruction :text="$t('OFFER.PURCHASE_INSTRUCTIONS')" />

    <Margins tag="section" class="margins__triple">
      <Container tag="div">
        <h3 class="h4">{{ $t('OFFER.PURCHASE_LIST') }}</h3>
      </Container>
      <div class="purchase-list__table background-grey-light">
        <Container tag="div">
          <PurchaseListTable />
        </Container>
      </div>
    </Margins>

    <div class="background-grey-light">
      <Container>
        <Separator size="small" hasLine />
        <Margins>
          <PriceSection
            :prices="[
              { price: totalPrice, text: $t('OFFER.FULL_TOTAL_AMOUNT') },
            ]"
            size="big"
          />
          <Margins v-if="infoExist">
            <Info
              v-if="outOfStock"
              variant="warning"
              :title="$t('SHOP.ONLY_IN_STOCK_ITEMS')"
            ></Info>
            <Info
              v-if="isShopRedirectError"
              variant="error"
              :title="$t('SHOP.UNAVAILABLE')"
              class="margins__half"
            ></Info>
          </Margins>
        </Margins>
        <PurchaseListFooter
          @downloadPdf="downloadPdf"
          class="margins__triple"
        />
      </Container>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ListInstruction from '@/components/offer/general/ListInstruction';
import PurchaseListTable from '@/components/offer/purchase-list/PurchaseListTable';
import PriceSection from '@/components/offer/general/PriceSection';
import PurchaseListFooter from '@/components/offer/purchase-list/PurchaseListFooter';
import Hero from '@/components/Hero';
import Separator from '@/components/Separator';
import Container from '@/components/Container';
import Margins from '@/components/Margins';
import Info from '@/components/Info.vue';
import { isInStock } from '@/utils';

export default {
  name: 'PurchaseList',
  components: {
    ListInstruction,
    PurchaseListTable,
    PriceSection,
    PurchaseListFooter,
    Hero,
    Separator,
    Container,
    Margins,
    Info,
  },
  computed: {
    ...mapState('offerStore', ['totalPrice']),
    ...mapState(['isShopRedirectError']),
    ...mapGetters('offerStore', ['getSelectedProducts']),
    outOfStock() {
      return !!this.getSelectedProducts.filter(
        (product) => !isInStock(product, product.packsNeeded),
      ).length;
    },
    infoExist() {
      return (
        (this.$i18n.locale === 'sv' && this.outOfStock) ||
        (this.$i18n.locale === 'sv' && this.isShopRedirectError)
      );
    },
  },
  methods: {
    ...mapActions(['downloadPdf']),
  },
};
</script>

<style lang="scss">
.purchase-list__table {
  padding-top: 1rem;
  padding-bottom: 2rem;
}
</style>
