<template>
  <Margins>
    <div class="grid-table">
      <div
        class="grid-table__row grid-table__row--purchase-list grid-table__row--header"
      >
        <div class="grid-table__column"></div>
        <div class="grid-table__column"></div>
        <div class="grid-table__column"></div>
        <div class="grid-table__column">{{ $t('OFFER.CHOSEN_QUANTITY') }}</div>
        <div class="grid-table__column">{{ $t('OFFER.PU') }}</div>
        <div class="grid-table__column text-nowrap">
          {{ $t('OFFER.No_PU') }}
        </div>
        <div class="grid-table__column grid-table__column--right">
          {{ $t('OFFER.PACKAGE_PRICE') }}
        </div>
        <div class="grid-table__column grid-table__column--right">
          {{ $t('OFFER.TOTAL_AMOUNT') }}
        </div>
      </div>
    </div>

    <PurchaseListTableData
      v-for="(product, index) in getSelectedProducts"
      :key="index"
      :selected-product="product"
    />
  </Margins>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Margins from '@/components/Margins.vue';
import PurchaseListTableData from './PurchaseListTableData';

export default {
  name: 'PurchaseListTable',
  components: {
    PurchaseListTableData,
    Margins,
  },
  computed: {
    ...mapState('offerStore', ['productsPerCategory', 'totalPrice']),
    ...mapGetters('offerStore', ['getSelectedProducts']),
  },
};
</script>
